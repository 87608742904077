import React from "react";
import cn from "classnames";
import styles from "./styles.module.scss";

const Header = ({ className, children, tracker = false }) => (
  <div className={cn(styles.header, className, { [styles.tracker]: tracker })}>
    <div className="container">{children}</div>
  </div>
);

export default Header;
