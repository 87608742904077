import React from "react";
import PropTypes from "prop-types";

import NavBar from "../NavBar";
import Seo from "../Seo";

const Layout = ({ children }) => (
  <>
    <a className="visually-hidden" href="#content">
      Skip to content
    </a>
    <Seo />
    <NavBar />
    <main id="content">{children}</main>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
