import React, { useState } from "react";
import cn from "classnames";
import NavItem from "./NavItem";
import Socials from "./Socials";

import styles from "./styles.module.scss";

const NavBar = () => {
  const [open, setOpen] = useState(false);

  return (
    <nav
      className={cn("navbar navbar-header navbar-expand-lg", styles.navBar)}
      aria-label="main-nav"
    >
      <div className="container position-relative">
        <a title="Totaljobs" href="/" className={styles.logo}>
          <span className="visually-hidden">Totaljobs</span>
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setOpen(!open)}
        >
          <span className={styles.bar} />
          <span className={styles.bar} />
          <span className={styles.bar} />
        </button>

        <div
          className={cn("collapse navbar-collapse", { show: open })}
          id="navbarSupportedContent"
        >
          <ul className={cn(styles.nav, "navbar-nav mr-auto")}>
            <NavItem to="/">The tracker</NavItem>
            <NavItem to="/industry/">Industry view</NavItem>
            <NavItem to="/insights/">Market insights</NavItem>
          </ul>
        </div>

        <Socials />
      </div>
    </nav>
  );
};

export default NavBar;
