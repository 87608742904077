import React from "react";
import cn from "classnames";
import { Link } from "gatsby";
import styles from "./styles.module.scss";

const NavItem = ({ to, children }) => (
  <li className={cn("type-20 nav-item", styles.navItem)}>
    <Link
      to={to}
      className={cn("nav-link", styles.navLink)}
      activeClassName={styles.active}
    >
      <span>{children}</span>
    </Link>
  </li>
);

export default NavItem;
