import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import FacebookSVG from "../../../images/social/facebook.inline.svg";
import TwitterSVG from "../../../images/social/twitter.inline.svg";
import LinkedInSVG from "../../../images/social/linkedIn.inline.svg";

import styles from "./styles.module.scss";

const Socials = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            shareText {
              twitter
              facebook
              linkedIn
            }
          }
        }
      }
    `
  );

  return (
    <div className={styles.container}>
      <a
        className={styles.link}
        rel="noopener noreferrer"
        target="_blank"
        href={`https://www.linkedin.com/sharing/share-offsite/?url=${site.siteMetadata.siteUrl}&title=${site.siteMetadata.shareText.linkedIn}`}
      >
        <LinkedInSVG className={styles.icon} />
        <span className="visually-hidden">Share on linkedin</span>
      </a>
      <a
        className={styles.link}
        rel="noopener noreferrer"
        target="_blank"
        href={`https://www.facebook.com/sharer/sharer.php?u=${site.siteMetadata.siteUrl}&quote=${site.siteMetadata.shareText.facebook}`}
      >
        <FacebookSVG className={styles.icon} />
        <span className="visually-hidden">Share on facebook</span>
      </a>
      <a
        className={styles.link}
        rel="noopener noreferrer"
        target="_blank"
        href={`https://twitter.com/intent/tweet?text=${site.siteMetadata.shareText.twitter}&url=${site.siteMetadata.siteUrl}`}
      >
        <TwitterSVG className={styles.icon} />
        <span className="visually-hidden">Share on twitter</span>
      </a>
    </div>
  );
};

export default Socials;
