import React from "react";
import cn from "classnames";
import styles from "./styles.module.scss";

const FooterNav = () => (
  <nav className={cn("navbar navbar-expand", styles.navbar)}>
    <span className={cn("navbar-text", styles.text)}>
      <small className={styles.grey}>
        <strong className="d-block type-16">
          Are you recruiting? Call the team on{" "}
          <a className={styles.footerLink} href="tel:03330145111">
            0333 0145 111
          </a>
        </strong>
        &copy; Totaljobs Group Ltd 2021
      </small>
    </span>

    <ul className={cn("navbar-nav", styles.footerLinks)}>
      <li className={cn("nav-item", styles.footerNavItem)}>
        <a
          href="https://www.totaljobs.com/privacy-policy"
          className={cn("nav-link", styles.footerLink)}
        >
          Privacy Policy
        </a>
      </li>
      <li className={cn("nav-item", styles.footerNavItem)}>
        <a
          href="https://www.totaljobs.com/cookies"
          className={cn("nav-link", styles.footerLink)}
        >
          Cookies
        </a>
      </li>
    </ul>
  </nav>
);

export default FooterNav;
